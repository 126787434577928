import React, { useState, useEffect, useCallback } from "react";
import WallHeight from "../../../components/OrderingSystemComponents/UniversalComponents/WallHeight";
import RoofDensity from "../../../components/OrderingSystemComponents/UniversalComponents/RoofDensity";
import FanBeam from "../../../components/OrderingSystemComponents/UniversalComponents/FanBeam";
import SkyviewSelection from "../../../components/OrderingSystemComponents/PavilionPageComponents/SkyLights";
import CleatsOrBond from "../../../components/OrderingSystemComponents/UniversalComponents/CleatsOrBond";
import LEDLightSelection from "../../../components/OrderingSystemComponents/PavilionPageComponents/LEDLights";
import ValueSeries from "../../../components/OrderingSystemComponents/UniversalComponents/ValueSeries";
import PersonalInfoSelection from "../../../components/OrderingSystemComponents/UniversalComponents/PersonalInfoSelection";
import LoadSelection from "../../../components/OrderingSystemComponents/UniversalComponents/LoadSelection";
import SunroomSubmitButton from "../../../components/OrderingSystemComponents/SunroomPageComponents/SunroomSubmitButton";
import PermitChoice from "../../../components/OrderingSystemComponents/SunroomPageComponents/SunroomPermitChoice";
import SunroomElectrical from "../../../components/OrderingSystemComponents/SunroomPageComponents/SunroomElectrical";
import VertChannel from "../../../components/OrderingSystemComponents/SunroomPageComponents/VertChannel";
import WindowWallStyle from "../../../components/OrderingSystemComponents/SunroomPageComponents/SunroomWindowWallStyle";
import RoomStyle from "../../../components/OrderingSystemComponents/SunroomPageComponents/RoomStyle";
import BackWallPanels from "../../../components/OrderingSystemComponents/SunroomPageComponents/SunroomBackWallPanels";
import Transoms from "../../../components/OrderingSystemComponents/SunroomPageComponents/Transoms";
import SunroomColors from "../../../components/OrderingSystemComponents/SunroomPageComponents/SunroomColors";
import ShingleRoof from "../../../components/OrderingSystemComponents/UniversalComponents/ShingleRoof";
import RoofPanelColors from "../../../components/OrderingSystemComponents/UniversalComponents/RoofPanelColors";
import ACWindows from "../../../components/OrderingSystemComponents/SunroomPageComponents/ACWindow";
import SunRoomWalls from "../../../components/OrderingSystemComponents/SunroomPageComponents/SunRoomWalls";
import SunRoomType from "../../../components/OrderingSystemComponents/SunroomPageComponents/SunRoomType";
import SunroomsDimensionsSelection from "../../../components/OrderingSystemComponents/SunroomPageComponents/SunroomsDimensions";
import StructureLayoutImages from "../../../components/OrderingSystemComponents/UniversalComponents/StructureLayout";
import AngledLayout from "../../../components/OrderingSystemComponents/SunroomPageComponents/SunRoomWallsComponents/AngledRoom/AngledLayout";
import AngledYesNo from "../../../components/OrderingSystemComponents/SunroomPageComponents/Angled";
import AngledWalls from "../../../components/OrderingSystemComponents/SunroomPageComponents/SunRoomWallsComponents/AngledRoom/AngledWalls";
import ScreenRoom from "../../../components/OrderingSystemComponents/SunroomPageComponents/ScreenRoomOption";
import PropTypes from "prop-types";
import ChairRailSelection from "../../../components/OrderingSystemComponents/ScreenRoomsPageComponents/ChairRail";
import BottomKPSelection from "../../../components/OrderingSystemComponents/ScreenRoomsPageComponents/BottomKP";
import TEMOCorners from "../../../components/OrderingSystemComponents/SunroomPageComponents/TEMOCorners";
import RoofSpanKey from "../../../components/OrderingSystemComponents/UniversalComponents/RoofSpanKey";
import VinylColorSelection from "../../../components/OrderingSystemComponents/ScreenRoomsPageComponents/VinylOptions";
import GlassRows from "../../../components/OrderingSystemComponents/SunroomPageComponents/SunRoomWallsComponents/Solarium/GlassPanels";
import NoSkyLights from "../../../components/OrderingSystemComponents/PavilionPageComponents/NoSkyLights";

const Sunrooms = ({ isScreenRoom }) => {
  const NeedWallCode = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/NEEDWALLCODE.jpg`;
  const isSunroom = true;
  const [lastWallLengthUpdate, setLastWallLengthUpdate] = useState(Date.now());
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sender, setSender] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [zip, setZip] = useState("");
  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [selectedWindLoadChange, setSelectedWindLoadChange] = useState("");
  const [selectedRoofLoadChange, setSelectedRoofLoadChange] = useState("");
  const [widthFeet, setWidthFeet] = useState("");
  const [widthInches, setWidthInches] = useState("");
  const [lengthFeet, setLengthFeet] = useState("");
  const [lengthInches, setLengthInches] = useState("");
  const [fieldsWithRedBorder, setFieldsWithRedBorder] = useState([]);
  const [permitSelected, setPermitSelected] = useState("");
  const [gradeHeightFeet, setGradeHeightFeet] = useState("");
  const [gradeHeightInches, setGradeHeightInches] = useState("");
  const [selectedPermitOption, setSelectedPermitOption] = useState("");
  const [selectedKneeWallOption, setSelectedKneeWallOption] = useState("");
  const [selectedFoundationOption, setSelectedFoundationOption] = useState("");
  const [selectedMountOption, setSelectedMountOption] = useState("");
  const [stepDownInches, setStepDownInches] = useState("");
  const [stepDownFeet, setStepDownFeet] = useState("");
  const [
    selectedExistingFoundationOption,
    setSelectedExistingFoundationOption,
  ] = useState("");
  const [selectedNewFoundationOption, setSelectedNewFoundationOption] =
    useState("");
  const [selectedFasciaInches, setSelectedFasciaInches] = useState("");
  const [selectedKneeWallFeet, setSelectedKneeWallFeetChange] = useState("");
  const [selectedKneeWallInches, setSelectedKneeWallInchesChange] =
    useState("");
  const [selectedShingle, setHandleShingleChange] = useState("");
  const [selectedFanBeam, setHandleFanBeam] = useState("");
  const [selectedRoofDensity, setSelectedRoofDensity] = useState("");
  const [heightAtHouse, setHeightAtHouse] = useState({
    heightfeet: "",
    heightinches: "",
  });
  const [frontHeight, setFrontHeight] = useState({
    frontfeet: "",
    frontinches: "",
  });
  const [selectedSkyviewChoice, setSelectedSkyviewChoice] = useState("");
  const [selectedLEDLightChoice, setSelectedLEDLightChoice] = useState("");
  const [selectedCleatsOrBond, setSelectedCleatsOrBond] = useState("");
  const [selectedValueSeries, setSelectedValueSeries] = useState("");
  const [deckFlashingOption, setDeckFlashingOption] = useState("");
  const [selectedElectricalOption, setSelectedElectricalOption] = useState("");
  const [selectedVertChannelOption, setSelectedVertChannelOption] =
    useState("");
  const [selectedWindowWallStyle, setSelectedWindowWallStyle] = useState("");
  const [selectedRoomStyle, setSelectedRoomStyle] = useState("");
  const [selectedBotTransom, setSelectedBotTransom] = useState("");
  const [selectedTopCustomReady, setSelectedTopCustomReady] = useState("");
  const [selectedBackWallPanels, setSelectedBackWallPanels] = useState("");
  const [frameOption, setFrameOption] = useState("");
  const [fasciaTrimOption, setFasciaTrimOption] = useState("");
  const [exteriorKpOption, setExteriorKpOption] = useState("");
  const [interiorKpOption, setInteriorKpOption] = useState("");
  const [selectedBotTransomOption, setSelectedBotTransomOption] = useState("");
  const [selectedTopTransomOption, setSelectedTopTransomOption] = useState("");
  const [selectedRoofPanelColorOption, setSelectedRoofPanelColorOption] =
    useState("");
  const [ACLeftOption, setACLeftOption] = useState("");
  const [ACCenterOption, setACCenterOption] = useState("");
  const [ACRightOption, setACRightOption] = useState("");
  const [selectedPavilionLayoutOption, setPavilionLayoutOption] = useState("");
  const [selectedDoorOrientation, setSelectedDoorOrientation] = useState([]);
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [doorOption, setDoorOption] = useState([]);
  const [doorWidth, setDoorWidth] = useState([]);
  const [selectedPatioDoorOrientation, setSelectedPatioDoorOrientation] =
    useState([]);
  const [selectedSwingDoorOrientation, setSelectedSwingDoorOrientation] =
    useState([]);
  const [wallLengthsFeet, setWallLengthsFeet] = useState([]);
  const [wallLengthsInches, setWallLengthsInches] = useState([]);
  const [SSHinges, setSSHinges] = useState("");
  const [selectedRailSideOption, setRailSideOption] = useState("");
  const [selectedRailAmountOption, setRailAmountOption] = useState({
    feet: "",
    inches: "",
  });
  const [selectedDoorHardware, setSelectedDoorHardware] = useState("");
  const [selectedSunRoomType, setSelectedSunRoomType] = useState("");
  const [selectedBreezeWay, setSelectedBreezeWay] = useState("");
  const [initialSteelPiers, setInitialSteelPiers] = useState("");
  const [deckWidthInchesOption, setdeckWidthInchesOption] = useState("");
  const [deckLengthInchesOption, setdeckLengthInchesOption] = useState("");
  const [deckWidthFeetOption, setdeckWidthFeetOption] = useState("");
  const [deckLengthFeetOption, setdeckLengthFeetOption] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [locations, setLocations] = useState({});

  const [selectedERLCodes, setSelectedERLCodes] = useState([]);
  const [selectedERRCodes, setSelectedERRCodes] = useState([]);
  const [selectedERFCodes, setSelectedERFCodes] = useState([]);

  const [doorERLOption, setDoorERLOption] = useState([]);
  const [doorERROption, setDoorERROption] = useState([]);
  const [doorERFOption, setDoorERFOption] = useState([]);

  const [doorERLWidth, setDoorERLWidth] = useState([]);
  const [doorERRWidth, setDoorERRWidth] = useState([]);
  const [doorERFWidth, setDoorERFWidth] = useState([]);

  const [selectedERLPatioDoorOrientation, setSelectedERLPatioDoorOrientation] =
    useState([]);
  const [selectedERRPatioDoorOrientation, setSelectedERRPatioDoorOrientation] =
    useState([]);
  const [selectedERFPatioDoorOrientation, setSelectedERFPatioDoorOrientation] =
    useState([]);

  const [selectedERLSwingDoorOrientation, setSelectedERLSwingDoorOrientation] =
    useState([]);
  const [selectedERRSwingDoorOrientation, setSelectedERRSwingDoorOrientation] =
    useState([]);
  const [selectedERFSwingDoorOrientation, setSelectedERFSwingDoorOrientation] =
    useState([]);

  const [wallERLLengthsFeet, setWallERLLengthsFeet] = useState([]);
  const [wallERRLengthsFeet, setWallERRLengthsFeet] = useState([]);
  const [wallERFLengthsFeet, setWallERFLengthsFeet] = useState([]);

  const [wallERLLengthsInches, setWallERLLengthsInches] = useState([]);
  const [wallERRLengthsInches, setWallERRLengthsInches] = useState([]);
  const [wallERFLengthsInches, setWallERFLengthsInches] = useState([]);

  const [leftCavities, setLeftCavities] = useState(1);
  const [rightCavities, setRightCavities] = useState(1);
  const [frontCavities, setFrontCavities] = useState(1);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState([]);
  const [selectedERFImages, setSelectedERFImages] = useState([]);
  const [selectedERLImages, setSelectedERLImages] = useState([]);
  const [selectedERRImages, setSelectedERRImages] = useState([]);
  const [selectedERLButtonIndex, setSelectedERLButtonIndex] = useState([]);
  const [selectedERRButtonIndex, setSelectedERRButtonIndex] = useState([]);
  const [selectedERFButtonIndex, setSelectedERFButtonIndex] = useState([]);
  const [leftSideDivs, setLeftSideDivs] = useState([]);
  const [rightSideDivs, setRightSideDivs] = useState([]);
  const [frontSideDivs, setFrontSideDivs] = useState([]);
  const [leftSidePickMeDivs, setLeftSidePickMeDivs] = useState([]);
  const [rightSidePickMeDivs, setRightSidePickMeDivs] = useState([]);
  const [frontSidePickMeDivs, setFrontSidePickMeDivs] = useState([]);
  const [verticalRightLines, setVerticalRightLines] = useState([]);
  const [pickMeRightDivs, setRightPickMes] = useState([]);
  const [blackRightSquares, setBlackRightSquares] = useState([]);
  const [horizontalLines, setHorizontalLines] = useState([]);
  const [blackFrontSquares, setBlackFrontSquares] = useState([]);
  const [pickMeFrontDivs, setFrontPickMes] = useState([]);
  const [verticalLeftLines, setVerticalLeftLines] = useState([]);
  const [blackLeftSquares, setBlackLeftSquares] = useState([]);
  const [pickMeLeftDivs, setLeftPickMes] = useState([]);
  const [indexFront, setIndexFront] = useState();
  const [indexLeft, setIndexLeft] = useState();
  const [indexRight, setIndexRight] = useState();
  const [openFrontSingleDrawing, setOpenFrontSingleDrawing] = useState(false);
  const [openLeftSingleDrawing, setOpenLeftSingleDrawing] = useState(false);
  const [openRightSingleDrawing, setOpenRightSingleDrawing] = useState(false);
  const [selectedAngled, setSelectedAngled] = useState("");
  const [selectedAngleOption, setSelectedAngleOption] = useState("");
  const [newKey, setKey] = useState(0);
  const [leftSideMissingWallLength, setLeftMissingWallLength] = useState([]);
  const [leftSideMissingWallDiv, setLeftSideMissingWallDiv] = useState([]);
  const [rightSideMissingWallLength, setRightMissingWallLength] = useState([]);
  const [rightSideMissingWallDiv, setRightSideMissingWallDiv] = useState([]);
  const [wallStraightLengthsFeet, setWallStraightLengthsFeet] = useState([
    "",
    "",
    "",
    "",
    "",
  ]);
  const [wallStraightLengthsInches, setWallStraightLengthsInches] = useState([
    "",
    "",
    "",
    "",
    "",
  ]);
  const [selectedStraightCodes, setSelectedStraightCodes] = useState([
    "",
    "",
    "",
    "",
    "",
  ]);
  const [selectedStraightImages, setSelectedStraightImages] = useState([
    NeedWallCode,
    NeedWallCode,
    NeedWallCode,
    NeedWallCode,
    NeedWallCode,
  ]);
  const [selectedStraightButtonIndex, setSelectedStraightButtonIndex] =
    useState(["", "", "", "", ""]);
  const [doorStraightWidth, setDoorStraightWidth] = useState([
    "",
    "",
    "",
    "",
    "",
  ]);
  const [doorStraightOption, setDoorStraightOption] = useState([
    "",
    "",
    "",
    "",
    "",
  ]);
  const [
    selectedStraightPatioDoorOrientation,
    setSelectedStraightPatioDoorOrientation,
  ] = useState(["", "", "", "", ""]);
  const [
    selectedStraightSwingDoorOrientation,
    setSelectedStraightSwingDoorOrientation,
  ] = useState(["", "", "", "", ""]);
  const [rightAngleFeet, setRightAngleFeet] = useState("");
  const [rightAngleInches, setRightAngleInches] = useState("");
  const [leftAngleFeet, setLeftAngleFeet] = useState("");
  const [leftAngleInches, setLeftAngleInches] = useState("");
  const [screenRoom, setScreenRoom] = useState("");
  const [selectedERLLocations, setSelectedERLLocations] = useState([]);
  const [selectedERRLocations, setSelectedERRLocations] = useState([]);
  const [selectedERFLocations, setSelectedERFLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [chairRailWidthFeet, setChairRailWidthFeetOption] = useState("");
  const [chairRailWidthInches, setChairRailWidthInchesOption] = useState("");
  const [bottomKPWidthFeet, setBottomKPWidthFeetOption] = useState("");
  const [bottomKPWidthInches, setBottomKPWidthInchesOption] = useState("");
  const [selectedTEMOCornersOption, setSelectedTEMOCornersOption] =
    useState("");
  const [screenDoorWidth, setScreenDoorWidth] = useState("");
  const [resetWallHeight, setResetWallHeight] = useState(false);
  const [tempFrontHeight, setTempFrontHeight] = useState({
    frontfeet: "",
    frontinches: "",
  });
  const [tempHeightAtHouse, setTempHeightAtHouse] = useState({
    heightfeet: "",
    heightinches: "",
  });
  const [shouldShowAlert, setShouldShowAlert] = useState(false);
  const [vinylOption, setVinylOption] = useState("");
  const [glassRows, setGlassRows] = useState(0);
  const [bays, setBays] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const PatioDoorOrientations = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/PatioDoorOrientations.jpg`;
  const SelectedRightOrientation = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/RightOperablePatioDoorSelected.jpg`;
  const SelectedLeftOrientation = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/LeftOperablePatioDoorSelected.jpg`;

  const [currentImage, setCurrentImage] = useState(PatioDoorOrientations);

  const [currentCADImagesFiles, setCurrentCADImagesFiles] = useState("");
  const [drawingImages, setDrawingImages] = useState([]);
  const [resetWalls, setResetWalls] = useState(false);

  const handleBays = (bays, dimensions) => {
    setBays(bays);

    const dimensionsRegex = /\((\d+)' (\d+(\.\d+)?)"\)/;
    const match = dimensionsRegex.exec(dimensions);
    console.log(dimensions);
    console.log("match", match);

    if (match) {
      const feet = match[1];
      const inches = match[2];
      handleDimensionChange(feet, inches, "width");
    } else {
      console.error("Invalid dimensions format");
    }
  };

  const handleGlassRows = (selected) => {
    setGlassRows(selected);
  };

  const handleVinylColorChange = (selectedVinylColor) => {
    setVinylOption(selectedVinylColor);
  };

  const handleSelectedScreenDoorWidthChoice = (width) => {
    setScreenDoorWidth(width);
  };

  const handleSwingDoorOrientation = (
    orientation,
    index,
    redFieldMessage,
    setSelectedSwingDoor,
  ) => {
    setSelectedSwingDoor((prevOrientations) => {
      const newOrientations = [...prevOrientations];
      newOrientations[index] = orientation;
      return newOrientations;
    });
    setCurrentImage(PatioDoorOrientations);

    setFieldsWithRedBorder((prevRedBorderFields) => {
      if (orientation) {
        return prevRedBorderFields.filter(
          (field) => field !== `${redFieldMessage}`,
        );
      } else {
        return [...prevRedBorderFields, `${redFieldMessage}`];
      }
    });
  };

  const handleSelectedPatioDoorOrientation = (
    selectedPatioOrientation,
    index,
    redFieldMessage,
    setSelectedPatioDoor,
  ) => {
    setSelectedPatioDoor((prevPatioDoorOrientation) => {
      const newPatioDoorOrientation = [...prevPatioDoorOrientation];
      newPatioDoorOrientation[index] = selectedPatioOrientation;
      return newPatioDoorOrientation;
    });

    setCurrentImage(
      selectedPatioOrientation === "Left-Operable"
        ? SelectedLeftOrientation
        : SelectedRightOrientation,
    );

    setFieldsWithRedBorder((prevRedBorderFields) => {
      if (selectedPatioOrientation) {
        return prevRedBorderFields.filter(
          (field) => field !== `${redFieldMessage}`,
        );
      } else {
        return [...prevRedBorderFields, `${redFieldMessage}`];
      }
    });
  };

  const handleDoorOptionChange = (
    option,
    index,
    setNewDoorOption,
    initialDoorOption,
    initialDoorWidth,
    setNewDoorWidth,
    redFieldMessage,
    setSelectedSwingDoor,
    setSelectedPatioDoor,
  ) => {
    const newDoorOption = [...initialDoorOption];
    newDoorOption[index] = option;
    setNewDoorOption(newDoorOption);
    handleDoorWidthChange(
      "",
      index,
      initialDoorWidth,
      setNewDoorWidth,
      "",
      setSelectedSwingDoor,
      setSelectedPatioDoor,
    );
    handleSwingDoorOrientation(
      "",
      index,
      redFieldMessage,
      setSelectedSwingDoor,
    );
    handleSelectedPatioDoorOrientation(
      "",
      index,
      redFieldMessage,
      setSelectedPatioDoor,
    );

    if (option !== "Yes") {
      const newDoorWidth = [...initialDoorWidth];
      newDoorWidth[index] = "";
      setNewDoorWidth(newDoorWidth);
    }

    if (isScreenRoom && option === "Yes") {
      const newDoorWidth = [...initialDoorWidth];
      newDoorWidth[index] = "3.5' Door";
      setNewDoorWidth(newDoorWidth);
    }

    setFieldsWithRedBorder((prevRedBorderFields) => {
      const dynamicMessage = `${redFieldMessage}`;
      if (option) {
        return prevRedBorderFields.filter((field) => field !== dynamicMessage);
      } else {
        return [...prevRedBorderFields, dynamicMessage];
      }
    });
  };

  useEffect(() => {
    let newRoofDensity = '3" 0.032 2 Pound';
    if (selectedSunRoomType === "Solarium") {
      setSelectedRoofDensity(newRoofDensity);
    } else {
      setSelectedRoofDensity("");
    }
  }, [selectedSunRoomType]);

  const handleDoorLocationChange = (
    location,
    index,
    initialDoorLocation,
    setNewDoorWidth,
    redFieldMessage,
  ) => {
    const newDoorWidth = [...initialDoorLocation];
    newDoorWidth[index] = location;
    setNewDoorWidth(newDoorWidth);

    setFieldsWithRedBorder((prevRedBorderFields) => {
      const dynamicMessage = `${redFieldMessage}`;
      if (location) {
        return prevRedBorderFields.filter((field) => field !== dynamicMessage);
      } else {
        return [...prevRedBorderFields, dynamicMessage];
      }
    });
  };

  const handleDoorWidthChange = (
    width,
    index,
    initialDoorWidth,
    setNewDoorWidth,
    redFieldMessage,
    setSelectedSwingDoor,
    setSelectedPatioDoor,
  ) => {
    const newDoorWidth = [...initialDoorWidth];
    newDoorWidth[index] = width;
    setNewDoorWidth(newDoorWidth);

    setSelectedSwingDoor((prevSwingDoorOrientation) => {
      const newSwingDoorOrientation = [...prevSwingDoorOrientation];
      newSwingDoorOrientation[index] = "";
      return newSwingDoorOrientation;
    });
    setSelectedPatioDoor((prevPatioDoorOrientation) => {
      const newPatioDoorOrientation = [...prevPatioDoorOrientation];
      newPatioDoorOrientation[index] = "";
      return newPatioDoorOrientation;
    });
    setCurrentImage(PatioDoorOrientations);

    setFieldsWithRedBorder((prevRedBorderFields) => {
      const dynamicMessage = `${redFieldMessage}`;
      if (width) {
        return prevRedBorderFields.filter((field) => field !== dynamicMessage);
      } else {
        return [...prevRedBorderFields, dynamicMessage];
      }
    });
  };
  const handleSelectedAngled = (selected) => {
    setSelectedAngled(selected);
    if (selected === "Yes") {
      handlePavilionLayoutOptionChange("");
    }
  };

  const handleCodeSelect = (
    selectedCode,
    initialSelectedCodes,
    index,
    redBorderField,
    setNewSelectedCodes,
  ) => {
    const newSelectedCodes = [...initialSelectedCodes];
    newSelectedCodes[index] = selectedCode;
    setNewSelectedCodes(newSelectedCodes);

    setFieldsWithRedBorder((prevRedBorderFields) => {
      if (selectedCode) {
        return prevRedBorderFields.filter(
          (field) => field !== `${redBorderField}`,
        );
      } else {
        return [...prevRedBorderFields, `${redBorderField}`];
      }
    });
  };

  useEffect(() => {
    let frontSize = Number(frontCavities);
    setWallERFLengthsFeet(Array(frontSize).fill(""));
    setWallERFLengthsInches(Array(frontSize).fill(""));
    setDoorERFOption(Array(frontSize).fill(""));
    setDoorERFWidth(Array(frontSize).fill(""));
    setSelectedERFPatioDoorOrientation(Array(frontSize).fill(""));
    setSelectedERFSwingDoorOrientation(Array(frontSize).fill(""));
    setSelectedERFCodes(Array(frontSize).fill(""));
    setSelectedERFImages(Array(frontSize).fill(NeedWallCode));
    setSelectedERFButtonIndex(Array(frontSize).fill(null));
    setSelectedERFLocations(Array(frontSize).fill(""));
  }, [NeedWallCode, frontCavities, resetWalls]);

  useEffect(() => {
    let leftSize = Number(leftCavities);
    setWallERLLengthsFeet(Array(leftSize).fill(""));
    setWallERLLengthsInches(Array(leftSize).fill(""));
    setDoorERLOption(Array(leftSize).fill(""));
    setDoorERLWidth(Array(leftSize).fill(""));
    setSelectedERLPatioDoorOrientation(Array(leftSize).fill(""));
    setSelectedERLSwingDoorOrientation(Array(leftSize).fill(""));
    setSelectedERLCodes(Array(leftSize).fill(""));
    setSelectedERLImages(Array(leftSize).fill(NeedWallCode));
    setSelectedERLButtonIndex(Array(leftSize).fill(null));
    setSelectedERLLocations(Array(leftSize).fill(""));
  }, [NeedWallCode, leftCavities, resetWalls]);

  useEffect(() => {
    let rightSize = Number(rightCavities);
    setSelectedERRImages(Array(rightSize).fill(NeedWallCode));
    setSelectedERRCodes(Array(rightSize).fill(""));
    setSelectedERRSwingDoorOrientation(Array(rightSize).fill(""));
    setSelectedERRPatioDoorOrientation(Array(rightSize).fill(""));
    setDoorERRWidth(Array(rightSize).fill(""));
    setWallERRLengthsFeet(Array(rightSize).fill(""));
    setWallERRLengthsInches(Array(rightSize).fill(""));
    setDoorERROption(Array(rightSize).fill(""));
    setSelectedERRButtonIndex(Array(rightSize).fill(null));
    setSelectedERRLocations(Array(rightSize).fill(""));
  }, [NeedWallCode, rightCavities, resetWalls]);

  const handlePavilionLayoutOptionChange = (selected) => {
    setPavilionLayoutOption(selected);
    setResetWalls(true);
  };

  const handleSelectedSunRoomType = (selected) => {
    setSelectedSunRoomType(selected);
    setSelectedTopTransomOption("");
    handleSelectedRoomStyle("");
    setResetWalls(true);
    setResetWallHeight(true);
    setKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    if (resetWalls) {
      setResetWalls(false);
    }
  }, [resetWalls]);

  const handleLocationChange = (event) => {
    var input = event.target.value;
    const updatedRedBorderFields = fieldsWithRedBorder.includes("location")
      ? input
        ? fieldsWithRedBorder.filter((field) => field !== "location")
        : fieldsWithRedBorder
      : input
        ? fieldsWithRedBorder
        : [...fieldsWithRedBorder, "location"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    setSelectedLocation(input);
  };

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputEmail);
    setIsValidEmail(isValid);
    return isValid;
  };

  const handleDeckDimensionChange = (feet, inches, type) => {
    if (type === "deck width") {
      setdeckWidthFeetOption(feet);
      setdeckWidthInchesOption(inches);
    } else if (type === "deck length") {
      setdeckLengthFeetOption(feet);
      setdeckLengthInchesOption(inches);
    }
  };

  const handleSteelPiers = (selectedSteelPiers) => {
    setInitialSteelPiers(selectedSteelPiers);
  };

  const handleScreenRoomChange = (selectedScreenRoom) => {
    setScreenRoom(selectedScreenRoom);
  };

  useEffect(() => {
    const size =
      selectedPavilionLayoutOption === "1"
        ? 3
        : selectedPavilionLayoutOption === "2"
          ? 2
          : 1;
    setSelectedDoorOrientation(Array(size).fill(""));
    setSelectedCodes(Array(size).fill(""));
    setDoorOption(Array(size).fill(""));
    setDoorWidth(Array(size).fill(""));
    setSelectedPatioDoorOrientation(Array(size).fill(""));
    setSelectedSwingDoorOrientation(Array(size).fill(""));
    setWallLengthsFeet(Array(size).fill(""));
    setWallLengthsInches(Array(size).fill(""));
    setSelectedImages(Array(size).fill(NeedWallCode));
    setSelectedButtonIndex(Array(size).fill(null));
    setSelectedLocations(Array(size).fill(""));
  }, [NeedWallCode, selectedPavilionLayoutOption, resetWalls]);

  const handleBreezeWayChange = (selectedBreezeWay) => {
    setSelectedBreezeWay(selectedBreezeWay);
    if (selectedBreezeWay === "Yes") {
      setRailSideOption("");
      let frontSize = 0;
      setWallERFLengthsFeet(Array(frontSize).fill(""));
      setWallERFLengthsInches(Array(frontSize).fill(""));
      setDoorERFOption(Array(frontSize).fill(""));
      setDoorERFWidth(Array(frontSize).fill(""));
      setSelectedERFPatioDoorOrientation(Array(frontSize).fill(""));
      setSelectedERFSwingDoorOrientation(Array(frontSize).fill(""));
      setSelectedERFCodes(Array(frontSize).fill(""));
      setSelectedERFImages(Array(frontSize).fill(NeedWallCode));
      setSelectedERFButtonIndex(Array(frontSize).fill(null));
    }
  };

  const handleRailSideOption = (selected) => {
    setRailSideOption(selected);
    if (selected === "RIGHT") {
      let leftSize = 0;
      setWallERLLengthsFeet(Array(leftSize).fill(""));
      setWallERLLengthsInches(Array(leftSize).fill(""));
      setDoorERLOption(Array(leftSize).fill(""));
      setDoorERLWidth(Array(leftSize).fill(""));
      setSelectedERLPatioDoorOrientation(Array(leftSize).fill(""));
      setSelectedERLSwingDoorOrientation(Array(leftSize).fill(""));
      setSelectedERLCodes(Array(leftSize).fill(""));
      setSelectedERLImages(Array(leftSize).fill(NeedWallCode));
      setSelectedERLButtonIndex(Array(leftSize).fill(null));
    }
    if (selected === "LEFT") {
      let rightSize = 0;
      setSelectedERRImages(Array(rightSize).fill(NeedWallCode));
      setSelectedERRCodes(Array(rightSize).fill(""));
      setSelectedERRSwingDoorOrientation(Array(rightSize).fill(""));
      setSelectedERRPatioDoorOrientation(Array(rightSize).fill(""));
      setDoorERRWidth(Array(rightSize).fill(""));
      setWallERRLengthsFeet(Array(rightSize).fill(""));
      setWallERRLengthsInches(Array(rightSize).fill(""));
      setDoorERROption(Array(rightSize).fill(""));
      setSelectedERRButtonIndex(Array(rightSize).fill(null));
    }
  };
  const handleRailAmountOption = (selected) => {
    setRailAmountOption(selected);
  };

  const handleDimensionChange = useCallback((feet, inches, type) => {
    if (type === "width") {
      setWidthFeet(feet);
      setWidthInches(inches);
    }
    if (type === "length") {
      setLengthFeet(feet);
      setLengthInches(inches);
    }
  }, []);

  const handleChairRailChange = (feet, inches) => {
    setChairRailWidthFeetOption(feet);
    setChairRailWidthInchesOption(inches);
  };

  const handleBottomKPChange = (feet, inches) => {
    setBottomKPWidthFeetOption(feet);
    setBottomKPWidthInchesOption(inches);
  };

  const handleSSHingesOptionChange = (selectedSSHinges) => {
    setSSHinges(selectedSSHinges);
    const updatedRedBorderFields = selectedSSHinges
      ? fieldsWithRedBorder.filter((field) => field !== "SS Hinges")
      : fieldsWithRedBorder.includes("SS Hinges")
        ? fieldsWithRedBorder
        : [...fieldsWithRedBorder, "SS Hinges"];

    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  const [previousValues, setPreviousValues] = useState([]);

  const handleWallLengthFeetChange = (
    setWallLengthsFeetFunction,
    wallLengthsFeetArray,
    fieldIdentifierBase,
    index,
    value,
    setSelectedSwingDoor,
    setSelectedPatioDoor,
  ) => {
    const updatedWallLengthsFeet = [...wallLengthsFeetArray];
    updatedWallLengthsFeet[index] = value;
    setWallLengthsFeetFunction(updatedWallLengthsFeet);
    if (
      (value < 4 && selectedSunRoomType !== "Premier View") ||
      (value < 5 && selectedSunRoomType === "Premier View")
    ) {
      handleDoorOptionChange(
        "No",
        index,
        setDoorOption,
        doorOption,
        doorWidth,
        setDoorWidth,
        `${fieldIdentifierBase} Door Option`,
        setSelectedSwingDoor,
        setSelectedPatioDoor,
      );
      handleDoorWidthChange(
        "",
        index,
        doorWidth,
        setDoorWidth,
        `${fieldIdentifierBase} Door Width`,
        setSelectedSwingDoor,
        setSelectedPatioDoor,
      );
    }
    if (
      (previousValues[index] < 4 &&
        value >= 4 &&
        selectedSunRoomType !== "Premier View" &&
        doorOption[index] === "No") ||
      (previousValues[index] < 5 &&
        value >= 5 &&
        selectedSunRoomType === "Premier View" &&
        doorOption[index] === "No")
    ) {
      handleDoorOptionChange(
        "",
        index,
        setDoorOption,
        doorOption,
        doorWidth,
        setDoorWidth,
        `${fieldIdentifierBase} Door Option`,
        setSelectedSwingDoor,
        setSelectedPatioDoor,
      );
      handleDoorWidthChange(
        "",
        index,
        doorWidth,
        setDoorWidth,
        `${fieldIdentifierBase} Door Width`,
        setSelectedSwingDoor,
        setSelectedPatioDoor,
      );
    }

    const updatedPreviousValues = [...previousValues];
    updatedPreviousValues[index] = value;
    setPreviousValues(updatedPreviousValues);

    setFieldsWithRedBorder((prevRedBorderFields) => {
      const fieldIdentifier = `${fieldIdentifierBase}`;
      if (value) {
        return prevRedBorderFields.filter((field) => field !== fieldIdentifier);
      } else {
        return prevRedBorderFields.includes(fieldIdentifier)
          ? prevRedBorderFields
          : [...prevRedBorderFields, fieldIdentifier];
      }
    });
  };

  const handleWallLengthInchesChange = (
    setWallLengthsInchesFunction,
    wallLengthsInchesArray,
    fieldIdentifierBase,
    index,
    inputValue,
  ) => {
    let value = inputValue.replace(/[^\d.]/g, "");

    const parts = value.split(".");
    if (parts.length > 2) {
      value = parts[0] + "." + parts.slice(1).join("");
    }
    if (parts.length > 1) {
      value = parts[0] + "." + parts[1].slice(0, 3);
    }

    if (!isNaN(parseFloat(value)) && value.match(/^\d*\.?\d*$/)) {
      let numericValue = parseFloat(value);
      if (!isNaN(numericValue)) {
        numericValue = Math.max(0, Math.min(11.999, numericValue));
        value = numericValue.toString();
      }
    }

    if (inputValue.endsWith(".")) {
      value = inputValue;
    }

    const updatedWallLengthsInches = [...wallLengthsInchesArray];
    updatedWallLengthsInches[index] = value;
    setWallLengthsInchesFunction(updatedWallLengthsInches);

    setFieldsWithRedBorder((prevRedBorderFields) => {
      const fieldIdentifier = `${fieldIdentifierBase}`;
      if (value && (parseFloat(value) >= 0 || value.endsWith("."))) {
        return prevRedBorderFields.filter((field) => field !== fieldIdentifier);
      } else {
        return prevRedBorderFields.includes(fieldIdentifier)
          ? prevRedBorderFields
          : [...prevRedBorderFields, fieldIdentifier];
      }
    });
  };

  useEffect(() => {
    var soffitdepthinfeet = 0;
    var remainingInches = 0;
    var soffitdepth = 0;
    var adjustedFeetFirst;
    var adjustedFirstLength;
    var adjustedFeetThird;
    var adjustedThirdLength;

    if (selectedAngled !== "Yes") {
      const wallLengthsFeetInt = wallLengthsFeet.map((length) =>
        length ? parseInt(length, 10) : null,
      );
      const wallLengthsInchesFloat = wallLengthsInches.map((length) =>
        length ? parseFloat(length) : null,
      );

      if (selectedFasciaInches !== "") {
        soffitdepth = parseFloat(selectedFasciaInches);
      }
      if (!isNaN(soffitdepth) && soffitdepth !== null) {
        soffitdepthinfeet = Math.floor(soffitdepth / 12);
        remainingInches = soffitdepth % 12;
      }

      let firstWallLength =
        Number.isFinite(wallLengthsFeetInt[0]) &&
        Number.isFinite(wallLengthsInchesFloat[0])
          ? wallLengthsFeetInt[0] * 12 + wallLengthsInchesFloat[0]
          : null;
      let thirdWallLength =
        Number.isFinite(wallLengthsFeetInt[2]) &&
        Number.isFinite(wallLengthsInchesFloat[2])
          ? wallLengthsFeetInt[2] * 12 + wallLengthsInchesFloat[2]
          : null;

      let totalFirstWallInches;
      if (selectedSunRoomType !== "Solarium") {
        totalFirstWallInches = firstWallLength + 12 - remainingInches;
      } else if (selectedSunRoomType === "Solarium") {
        totalFirstWallInches = firstWallLength - remainingInches;
      }

      let totalThirdWallInches;
      if (selectedSunRoomType !== "Solarium") {
        totalThirdWallInches = thirdWallLength + 12 - remainingInches;
      } else if (selectedSunRoomType === "Solarium") {
        totalThirdWallInches = thirdWallLength - remainingInches;
      }

      if (totalFirstWallInches < 0) {
        adjustedFeetFirst = 0;
        adjustedFirstLength = 0;
      } else {
        adjustedFeetFirst =
          Math.floor(totalFirstWallInches / 12) - soffitdepthinfeet;
        adjustedFirstLength = totalFirstWallInches % 12;
      }

      if (totalThirdWallInches < 0) {
        adjustedFeetThird = 0;
        adjustedThirdLength = 0;
      } else {
        adjustedFeetThird =
          Math.floor(totalThirdWallInches / 12) - soffitdepthinfeet;
        adjustedThirdLength = totalThirdWallInches % 12;
      }

      if (adjustedFirstLength < 0) {
        adjustedFirstLength += 12;
        adjustedFeetFirst = Math.max(0, adjustedFeetFirst - 1);
      }

      if (adjustedThirdLength < 0) {
        adjustedThirdLength += 12;
        adjustedFeetThird = Math.max(0, adjustedFeetThird - 1);
      }

      if (selectedPavilionLayoutOption !== "3") {
        if (Number.isFinite(firstWallLength)) {
          if (thirdWallLength === null || firstWallLength >= thirdWallLength) {
            handleDimensionChange(
              adjustedFeetFirst.toString(),
              adjustedFirstLength.toString(),
              "length",
            );
          } else if (
            Number.isFinite(thirdWallLength) &&
            thirdWallLength > firstWallLength
          ) {
            handleDimensionChange(
              adjustedFeetThird.toString(),
              adjustedThirdLength.toString(),
              "length",
            );
          }
        }
      }

      if (
        (selectedPavilionLayoutOption !== "3" &&
          wallLengthsFeet[1] &&
          wallLengthsInches[1] &&
          Number.isFinite(parseInt(wallLengthsFeet[1], 10)) &&
          Number.isFinite(parseFloat(wallLengthsInches[1]))) ||
        (selectedPavilionLayoutOption === "3" &&
          wallLengthsFeet[0] &&
          wallLengthsInches[0] &&
          Number.isFinite(parseInt(wallLengthsFeet[0], 10)) &&
          Number.isFinite(parseFloat(wallLengthsInches[0])))
      ) {
        if (selectedPavilionLayoutOption === "1") {
          handleDimensionChange(
            (parseInt(wallLengthsFeet[1], 10) + 2).toString(),
            wallLengthsInches[1],
            "width",
          );
        } else if (selectedPavilionLayoutOption === "2") {
          handleDimensionChange(
            (parseInt(wallLengthsFeet[1], 10) + 1).toString(),
            wallLengthsInches[1],
            "width",
          );
        } else if (selectedPavilionLayoutOption === "3") {
          handleDimensionChange(
            parseInt(wallLengthsFeet[0], 10).toString(),
            wallLengthsInches[0],
            "width",
          );
        }
      }
    }
  }, [
    handleDimensionChange,
    selectedAngled,
    selectedFasciaInches,
    selectedPavilionLayoutOption,
    selectedSunRoomType,
    wallLengthsFeet,
    wallLengthsInches,
  ]);

  useEffect(() => {
    var soffitdepthinfeet = 0;
    var remainingInches = 0;
    var soffitdepth = 0;
    var adjustedFeetFirst;
    var adjustedFirstLength;
    var adjustedFeetThird;
    var adjustedThirdLength;
    var leftanglefeet;
    if (selectedAngled === "Yes") {
      if (Number(leftAngleFeet) !== 0 && Number(leftAngleFeet) !== null) {
        leftanglefeet = Number(leftAngleFeet);
      } else {
        leftanglefeet = 0;
      }
      var leftangleinches;
      if (Number(leftAngleInches) !== 0 && Number(leftAngleInches) !== null) {
        leftangleinches = Number(leftAngleInches);
      } else {
        leftangleinches = 0;
      }
      var rightanglefeet;
      if (Number(rightAngleFeet) !== 0 && Number(rightAngleFeet) !== null) {
        rightanglefeet = Number(rightAngleFeet);
      } else {
        rightanglefeet = 0;
      }
      var rightangleinches;
      if (Number(rightAngleInches) !== 0 && Number(rightAngleInches) !== null) {
        rightangleinches = Number(rightAngleInches);
      } else {
        rightangleinches = 0;
      }

      const wallStraightLengthsFeetInt = wallStraightLengthsFeet.map(
        (length) => (length ? parseInt(length, 10) : null),
      );
      const wallStraightLengthsInchesFloat = wallStraightLengthsInches.map(
        (length) => (length ? parseFloat(length) : null),
      );

      var leftStraightWallFeet = Number(wallStraightLengthsFeetInt[0]);
      var rightStraightWallFeet = Number(wallStraightLengthsFeetInt[2]);
      var frontStraightWallFeet = Number(wallStraightLengthsFeetInt[1]);
      var leftStraightWallInches = Number(wallStraightLengthsInchesFloat[0]);
      var rightStraightWallInches = Number(wallStraightLengthsInchesFloat[2]);
      var frontStraightWallInches = Number(wallStraightLengthsInchesFloat[1]);

      if (selectedFasciaInches !== "") {
        soffitdepth = parseFloat(selectedFasciaInches);
      }
      if (!isNaN(soffitdepth) && soffitdepth !== null) {
        soffitdepthinfeet = Math.floor(soffitdepth / 12);
        remainingInches = soffitdepth % 12;
      }

      let firstWallLength =
        leftStraightWallFeet * 12 +
        leftStraightWallInches +
        leftanglefeet * 12 +
        leftangleinches;
      let thirdWallLength =
        rightStraightWallFeet * 12 +
        rightStraightWallInches +
        rightanglefeet * 12 +
        rightangleinches;
      let totalFirstWallInches = Number(firstWallLength) + 12 - remainingInches;
      let totalThirdWallInches = Number(thirdWallLength) + 12 - remainingInches;

      if (totalFirstWallInches < 0) {
        adjustedFeetFirst = 0;
        adjustedFirstLength = 0;
      } else {
        adjustedFeetFirst =
          Math.floor(totalFirstWallInches / 12) - soffitdepthinfeet;
        adjustedFirstLength = totalFirstWallInches % 12;
      }

      if (totalThirdWallInches < 0) {
        adjustedFeetThird = 0;
        adjustedThirdLength = 0;
      } else {
        adjustedFeetThird =
          Math.floor(totalThirdWallInches / 12) - soffitdepthinfeet;
        adjustedThirdLength = totalThirdWallInches % 12;
      }

      if (adjustedFirstLength < 0) {
        adjustedFirstLength += 12;
        adjustedFeetFirst =
          Math.max(0, adjustedFeetFirst - 1) - soffitdepthinfeet;
      }

      if (adjustedThirdLength < 0) {
        adjustedThirdLength += 12;
        adjustedFeetThird =
          Math.max(0, adjustedFeetThird - 1) - soffitdepthinfeet;
      }

      if (
        thirdWallLength === null ||
        firstWallLength >= thirdWallLength ||
        thirdWallLength.toString() === "12"
      ) {
        if (adjustedFeetFirst.toString() !== "1") {
          handleDimensionChange(
            adjustedFeetFirst.toString(),
            adjustedFirstLength.toString(),
            "length",
          );
        }
      } else if (
        firstWallLength === null ||
        thirdWallLength > firstWallLength ||
        firstWallLength.toString() === "12"
      ) {
        if (adjustedFeetThird.toString() !== "1") {
          handleDimensionChange(
            adjustedFeetThird.toString(),
            adjustedThirdLength.toString(),
            "length",
          );
        }
      }

      let roofWidthFeet =
        frontStraightWallFeet + leftanglefeet + rightanglefeet + 2;
      let roofWidthInches =
        frontStraightWallInches + leftangleinches + rightangleinches;
      if (roofWidthFeet.toString() !== "2")
        handleDimensionChange(
          roofWidthFeet.toString(),
          roofWidthInches.toString(),
          "width",
        );
    }
  }, [
    handleDimensionChange,
    leftAngleFeet,
    leftAngleInches,
    rightAngleFeet,
    rightAngleInches,
    selectedAngleOption,
    selectedAngled,
    selectedFasciaInches,
    selectedPavilionLayoutOption,
    wallLengthsFeet,
    wallLengthsInches,
    wallStraightLengthsFeet,
    wallStraightLengthsInches,
  ]);

  const handleSelectedLEDLightChoice = (selected) => {
    setSelectedLEDLightChoice(selected);
  };

  const handleSelectedCleatsOrBond = (selected) => {
    setSelectedCleatsOrBond(selected);
  };

  const handleSelectedSkyviewChoice = (selected) => {
    setSelectedSkyviewChoice(selected);
  };
  const handleShingleChange = (selectedShingle) => {
    setHandleShingleChange(selectedShingle);
  };

  const handleHeightChange = useCallback(({ heightAtHouse, frontHeight }) => {
    setHeightAtHouse(heightAtHouse);
    setFrontHeight(frontHeight);
  }, []);

  useEffect(() => {
    if (resetWallHeight || shouldShowAlert) {
      const newHeight = { heightfeet: "", heightinches: "" };
      const newFront = { frontfeet: "", frontinches: "" };

      handleHeightChange({ heightAtHouse: newHeight, frontHeight: newFront });
      setTempHeightAtHouse(newHeight);
      setTempFrontHeight(newFront);
      setResetWallHeight(false);
    }
  }, [
    resetWallHeight,
    shouldShowAlert,
    handleHeightChange,
    setTempHeightAtHouse,
    setTempFrontHeight,
    setResetWallHeight,
  ]);

  const handleRedBorderCheck = (missingFields) => {
    setFieldsWithRedBorder(missingFields);
  };

  const handleRoofDensity = (selectedRoofDensity) => {
    setSelectedRoofDensity(selectedRoofDensity);
  };

  const handleFanBeamOption = (selectedFanBeam) => {
    setHandleFanBeam(selectedFanBeam);
  };

  const handleFasciaInchesOption = (
    selectedFasciaInchesOption,
    selectedMountOption,
  ) => {
    if (selectedMountOption === "A") {
      setSelectedFasciaInches(selectedFasciaInchesOption);
    } else {
      setSelectedFasciaInches("");
    }
  };

  const handleKneeWallFeetChange = (selectedKneeWallFeetOption) => {
    setSelectedKneeWallFeetChange(selectedKneeWallFeetOption);
    if (selectedKneeWallOption === "no") {
      setSelectedKneeWallFeetChange("");
    }
  };

  const handleKneeWallInchesChange = (selectedKneeWallInchesOption) => {
    setSelectedKneeWallInchesChange(selectedKneeWallInchesOption);
    if (selectedKneeWallOption === "no") {
      setSelectedKneeWallInchesChange("");
    }
  };

  const handleFoundationChange = (selectedFoundation) => {
    setSelectedFoundationOption(selectedFoundation);
    if (selectedFoundation !== "existingconcrete") {
      setSelectedExistingFoundationOption("");
    }
    if (selectedFoundation !== "newconcrete") {
      setSelectedNewFoundationOption("");
    }
    if (
      selectedFoundation !== "existingwood" &&
      selectedFoundation !== "nontemowood"
    ) {
      setGradeHeightFeet("");
      setGradeHeightInches("");
    }
  };
  const handleMountChange = (selectedMount) => {
    setSelectedMountOption(selectedMount);
    setSelectedFasciaInches("");
  };
  const handleExistingFoundationChange = (selectedExistingFoundation) => {
    setSelectedExistingFoundationOption(selectedExistingFoundation);
  };
  const handleNewFoundationChange = (selectedNewFoundation) => {
    setSelectedNewFoundationOption(selectedNewFoundation);
  };
  const handleGradeHeightOption = (feet, inches) => {
    setGradeHeightFeet(feet);
    setGradeHeightInches(inches);
  };
  const handleStepDownChange = (feet, inches) => {
    setStepDownFeet(feet);
    setStepDownInches(inches);
  };

  const [, setKneeWallData] = useState({
    feet: "",
    inches: "",
  });

  const handleKneeWallChange = (selectedKneeWall) => {
    if (selectedKneeWall === "no") {
      setKneeWallData({
        feet: "",
        inches: "",
      });
    }
    setSelectedKneeWallOption(selectedKneeWall);
  };

  const handlePermitChange = (selectedPermit) => {
    setSelectedPermitOption(selectedPermit);
  };

  const handlePersonalInfoChange = (info) => {
    setFirstName(info.firstName);
    setLastName(info.lastName);
    setSender(info.sender);
    setEmail(info.email);
    setAddress(info.address);
    setCity(info.city);
    setStateProvince(info.stateProvince);
    setZip(info.zip);
    setPurchaseOrder(info.purchaseOrder);
  };

  const handleWindLoadOption = (selectedWindLoad) => {
    setSelectedWindLoadChange(selectedWindLoad);
  };

  const handleRoofLoadOption = (selectedRoofLoad) => {
    setSelectedRoofLoadChange(selectedRoofLoad);
  };

  const handlePermitSelection = (selected) => {
    setPermitSelected(selected);
  };

  const handleValueSeriesOption = (selected) => {
    setSelectedValueSeries(selected);
  };

  const handleDeckFlashingChange = (selected) => {
    setDeckFlashingOption(selected);
  };

  const handleSelectedElectricalChoice = (selected) => {
    setSelectedElectricalOption(selected);
  };
  const handleSelectedTEMOCornersChoice = (selected) => {
    setSelectedTEMOCornersOption(selected);
  };

  const handleVertChannelOption = (selected) => {
    setSelectedVertChannelOption(selected);
  };
  const handleSelectedWindowWallStyle = (selected) => {
    setSelectedWindowWallStyle(selected);
  };
  const handleSelectedRoomStyle = (selected) => {
    handleSelectedAngled("");
    setSelectedAngled("");
    setSelectedRoomStyle(selected);
    if (selected === "Cathedral") {
      setSelectedCleatsOrBond("BOND-TAPE");
    }
    if (selected === "Existing Roof") {
      setSelectedAngled("No");
    }
  };

  const handleSelectedBotTransom = (selected) => {
    setSelectedBotTransom(selected);
  };

  const handleSelectedTopCustomReady = (selected) => {
    setSelectedTopCustomReady(selected);
  };
  const handleBackWallPanelsOption = (selected) => {
    setSelectedBackWallPanels(selected);
  };
  const handleFrameOption = (selected) => {
    setFrameOption(selected);
    setFasciaTrimOption(selected);
    setExteriorKpOption(selected);
    setInteriorKpOption(selected);
  };
  const handleFasciaTrimOption = (selected) => {
    setFasciaTrimOption(selected);
  };
  const handleExteriorKpOption = (selected) => {
    setExteriorKpOption(selected);
  };
  const handleInteriorKpOption = (selected) => {
    setInteriorKpOption(selected);
  };
  const handleBotTransomOption = (selected) => {
    setSelectedBotTransomOption(selected);
  };
  const handleTopTransomOption = (selected) => {
    setSelectedTopTransomOption(selected);
    setResetWallHeight(true);
  };
  const handleRoofPanelColorOption = (selected) => {
    setSelectedRoofPanelColorOption(selected);
  };
  const handleACLeftOption = (selected) => {
    setACLeftOption(selected);
  };
  const handleACCenterOption = (selected) => {
    setACCenterOption(selected);
  };
  const handleACRightOption = (selected) => {
    setACRightOption(selected);
  };

  useEffect(() => {
    if (
      (Number(lengthFeet) > 15 || Number(lengthFeet) < 7) &&
      Number(lengthFeet) !== 0
    ) {
      handleSelectedSkyviewChoice("Skyview NO");
    }
  }, [lengthFeet, selectedSkyviewChoice]);

  useEffect(() => {
    if (selectedSkyviewChoice === "Skyview YES") {
      handleRoofDensity('4 1/4" 0.032 2 pound');
    }
  });

  return (
    <div className="pavilion">
      <div className="pergola-grid">
        <div className="style-choices">
          <SunRoomType
            handleSelectedSunRoomType={handleSelectedSunRoomType}
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            isSunroom={isSunroom}
            isScreenRoom={isScreenRoom}
            selectedSunRoomType={selectedSunRoomType}
          />
          <RoomStyle
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            handleSelectedRoomStyle={handleSelectedRoomStyle}
            selectedSunRoomType={selectedSunRoomType}
            selectedRoomStyle={selectedRoomStyle}
            setSelectedRoomStyle={setSelectedRoomStyle}
          />
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            {!isScreenRoom && (
              <WindowWallStyle
                redBorderFields={fieldsWithRedBorder}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                handleSelectedWindowWallStyle={handleSelectedWindowWallStyle}
                selectedRoomStyle={selectedRoomStyle}
              />
            )}
            {selectedRoomStyle !== "Existing Roof" &&
              !isScreenRoom &&
              selectedSunRoomType !== "Solarium" && (
                <AngledYesNo
                  redBorderFields={fieldsWithRedBorder}
                  setFieldsWithRedBorder={setFieldsWithRedBorder}
                  handleSelectedAngled={handleSelectedAngled}
                  setSelectedAngled={setSelectedAngled}
                  selectedAngled={selectedAngled}
                />
              )}
          </div>
          {!isScreenRoom && (
            <Transoms
              redBorderFields={fieldsWithRedBorder}
              setFieldsWithRedBorder={setFieldsWithRedBorder}
              handleSelectedBotTransom={handleSelectedBotTransom}
              handleBotTransomOption={handleBotTransomOption}
              handleTopTransomOption={handleTopTransomOption}
              selectedSunRoomType={selectedSunRoomType}
              handleSelectedTopCustomReady={handleSelectedTopCustomReady}
              selectedTopTransomOption={selectedTopTransomOption}
              setSelectedTopTransomOption={setSelectedTopTransomOption}
            />
          )}
          {isScreenRoom && selectedSunRoomType !== "Linear Screen" && (
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Transoms
                redBorderFields={fieldsWithRedBorder}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                handleSelectedBotTransom={handleSelectedBotTransom}
                handleBotTransomOption={handleBotTransomOption}
                handleTopTransomOption={handleTopTransomOption}
                selectedSunroomType={selectedSunRoomType}
                handleSelectedTopCustomReady={handleSelectedTopCustomReady}
                selectedTopTransomOption={selectedTopTransomOption}
                setSelectedTopTransomOption={setSelectedTopTransomOption}
                isScreenRoom={isScreenRoom}
              />
              {selectedRoomStyle !== "Existing Roof" &&
                selectedSunRoomType !== "Linear Screen" && (
                  <AngledYesNo
                    redBorderFields={fieldsWithRedBorder}
                    setFieldsWithRedBorder={setFieldsWithRedBorder}
                    handleSelectedAngled={handleSelectedAngled}
                    setSelectedAngled={setSelectedAngled}
                    selectedAngled={selectedAngled}
                  />
                )}
            </div>
          )}
          <SunroomColors
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            handleFrameOption={handleFrameOption}
            handleFasciaTrimOption={handleFasciaTrimOption}
            handleExteriorKpOption={handleExteriorKpOption}
            handleInteriorKpOption={handleInteriorKpOption}
            frameOption={frameOption}
            fasciaTrimOption={fasciaTrimOption}
            exteriorKpOption={exteriorKpOption}
            interiorKpOption={interiorKpOption}
            selectedRoomStyle={selectedRoomStyle}
            isScreenRoom={isScreenRoom}
            selectedSunRoomType={selectedSunRoomType}
            selectedValueSeries={selectedValueSeries}
          />
          <ACWindows
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            handleACLeftOption={handleACLeftOption}
            handleACCenterOption={handleACCenterOption}
            handleACRightOption={handleACRightOption}
          />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <SunroomElectrical
              redBorderFields={fieldsWithRedBorder}
              setFieldsWithRedBorder={setFieldsWithRedBorder}
              handleSelectedElectricalChoice={handleSelectedElectricalChoice}
            />
            <VertChannel
              handleVertChannelOption={handleVertChannelOption}
              redBorderFields={fieldsWithRedBorder}
              setFieldsWithRedBorder={setFieldsWithRedBorder}
            />
          </div>
          {selectedSunRoomType === "Vinyl View" && (
            <VinylColorSelection
              handleVinylColorChange={handleVinylColorChange}
              redBorderFields={fieldsWithRedBorder}
              setFieldsWithRedBorder={setFieldsWithRedBorder}
            />
          )}
          {selectedSunRoomType === "Linear Screen" && (
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}
            >
              <ChairRailSelection
                handleChairRailChange={handleChairRailChange}
                redBorderFields={fieldsWithRedBorder}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
              />
              <BottomKPSelection
                handleBottomKPChange={handleBottomKPChange}
                redBorderFields={fieldsWithRedBorder}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
              />
            </div>
          )}
        </div>
        <div className="personalinfo-choices">
          <PersonalInfoSelection
            onChange={handlePersonalInfoChange}
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            validateEmail={validateEmail}
            isValidEmail={isValidEmail}
            handleLocationChange={handleLocationChange}
            locations={locations}
            setLocations={setLocations}
          />
        </div>
        <div className="dimensionthings">
          <div className="dimensions-load">
            {selectedRoomStyle !== "Existing Roof" && (
              <div className="dimension-choices">
                <SunroomsDimensionsSelection
                  handleDimensionChange={handleDimensionChange}
                  redBorderFields={fieldsWithRedBorder}
                  setFieldsWithRedBorder={setFieldsWithRedBorder}
                  lengthFeet={lengthFeet}
                  lengthInches={lengthInches}
                  widthFeet={widthFeet}
                  widthInches={widthInches}
                  selectedSunRoomType={selectedSunRoomType}
                />
              </div>
            )}
            <div className="load-choices">
              <LoadSelection
                handleRoofLoadOption={handleRoofLoadOption}
                handleWindLoadOption={handleWindLoadOption}
                redBorderFields={fieldsWithRedBorder}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                selectedRoomStyle={selectedRoomStyle}
              />
            </div>
          </div>
          <div>
            <WallHeight
              redBorderFields={fieldsWithRedBorder}
              setFieldsWithRedBorder={setFieldsWithRedBorder}
              onHeightChange={handleHeightChange}
              roofstyle={selectedRoomStyle}
              selectedSunRoomType={selectedSunRoomType}
              selectedTopTransomOption={selectedTopTransomOption}
              heightAtHouse={heightAtHouse}
              setHeightAtHouse={setHeightAtHouse}
              frontHeight={frontHeight}
              setFrontHeight={setFrontHeight}
              newKey={newKey.toString()}
              resetWallHeight={resetWallHeight}
              setResetWallHeight={setResetWallHeight}
              setShouldShowAlert={setShouldShowAlert}
              shouldShowAlert={shouldShowAlert}
              tempFrontHeight={tempFrontHeight}
              tempHeightAtHouse={tempHeightAtHouse}
              setTempFrontHeight={setTempFrontHeight}
              setTempHeightAtHouse={setTempHeightAtHouse}
              isScreenRoom={isScreenRoom}
              isSunRoom={isSunroom}
            />
          </div>
        </div>
        {selectedRoomStyle !== "Existing Roof" && (
          <>
            <div className="density-fanbeam">
              <div className="skyview">
                {selectedSunRoomType !== "Solarium" && (
                  <ShingleRoof
                    setFieldsWithRedBorder={setFieldsWithRedBorder}
                    redBorderFields={fieldsWithRedBorder}
                    handleShingleChange={handleShingleChange}
                    selectedSkyviewChoice={selectedSkyviewChoice}
                    handleSelectedSkyviewChoice={handleSelectedSkyviewChoice}
                  />
                )}
                {selectedRoofLoadChange !== "" &&
                  lengthFeet !== "" &&
                  lengthInches !== "" &&
                  selectedSunRoomType !== "Solarium" &&
                  selectedSkyviewChoice !== "Skyview YES" && (
                    <RoofDensity
                      handleRoofDensity={handleRoofDensity}
                      redBorderFields={fieldsWithRedBorder}
                      setFieldsWithRedBorder={setFieldsWithRedBorder}
                      lengthFeet={lengthFeet}
                      lengthInches={lengthInches}
                      selectedRoofLoadChange={selectedRoofLoadChange.toString()}
                      selectedShingle={selectedShingle}
                      isScreenRoom={isScreenRoom}
                      selectedSunRoomType={selectedSunRoomType}
                    />
                  )}
                {selectedSkyviewChoice === "Skyview YES" && (
                  <LEDLightSelection
                    redBorderFields={fieldsWithRedBorder}
                    setFieldsWithRedBorder={setFieldsWithRedBorder}
                    handleSelectedLEDLightChoice={handleSelectedLEDLightChoice}
                    selectedSkyviewChoice={selectedSkyviewChoice}
                  />
                )}
                {selectedSunRoomType === "Solarium" && (
                  <GlassRows
                    redBorderFields={fieldsWithRedBorder}
                    setFieldsWithRedBorder={setFieldsWithRedBorder}
                    handleGlassRows={handleGlassRows}
                    lengthFeet={lengthFeet}
                    lengthInches={lengthInches}
                  />
                )}
              </div>
              <div className="skyview">
                {selectedRoomStyle === "STUDIO" &&
                selectedSunRoomType !== "Solarium" &&
                selectedAngled !== "Yes" &&
                Number(lengthFeet) <= 15 &&
                Number(lengthFeet) >= 7 ? (
                  <SkyviewSelection
                    handleSelectedSkyviewChoice={handleSelectedSkyviewChoice}
                    setSelectedSkyviewChoice={setSelectedSkyviewChoice}
                    selectedSkyviewChoice={selectedSkyviewChoice}
                    redBorderFields={fieldsWithRedBorder}
                    setFieldsWithRedBorder={setFieldsWithRedBorder}
                    handleSelectedLEDLightChoice={handleSelectedLEDLightChoice}
                    handleSelectedCleatsOrBond={handleSelectedCleatsOrBond}
                    handleDimensionChange={handleDimensionChange}
                    handleShingleChange={handleShingleChange}
                    selectedShingle={selectedShingle}
                  />
                ) : (
                  Number(lengthFeet) !== 0 &&
                  selectedSunRoomType !== "Solarium" && (
                    <NoSkyLights
                      lengthFeet={lengthFeet}
                      selectedRoomStyle={selectedRoomStyle}
                      selectedAngled={selectedAngled}
                    />
                  )
                )}
                {selectedSkyviewChoice !== "Skyview YES" &&
                  selectedShingle !== "YES" &&
                  selectedRoomStyle !== "Cathedral" &&
                  selectedSunRoomType !== "Solarium" && (
                    <CleatsOrBond
                      redBorderFields={fieldsWithRedBorder}
                      setFieldsWithRedBorder={setFieldsWithRedBorder}
                      handleSelectedCleatsOrBond={handleSelectedCleatsOrBond}
                      selectedSkyviewChoice={selectedSkyviewChoice}
                      selectedShingle={selectedShingle}
                    />
                  )}
                {(selectedShingle === "YES" ||
                  selectedSkyviewChoice === "Skyview YES") && (
                  <div className="cleatsorbond"></div>
                )}
                {Number(lengthFeet) === 0 && (
                  <div
                    className="cleatsorbond"
                    style={{ marginLeft: "1%" }}
                  ></div>
                )}
              </div>
            </div>
          </>
        )}
        <div className="permit-choices">
          <PermitChoice
            onPermitSelection={handlePermitSelection}
            handlePermitChange={handlePermitChange}
            handleKneeWallChange={handleKneeWallChange}
            handleFoundationChange={handleFoundationChange}
            handleExistingFoundationChange={handleExistingFoundationChange}
            handleNewFoundationChange={handleNewFoundationChange}
            handleGradeHeightOption={handleGradeHeightOption}
            handleKneeWallFeetChange={handleKneeWallFeetChange}
            handleKneeWallInchesChange={handleKneeWallInchesChange}
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            permitSelected={permitSelected}
            handleMountChange={handleMountChange}
            handleStepDownChange={handleStepDownChange}
            handleFasciaInchesOption={handleFasciaInchesOption}
            selectedMountOption={selectedMountOption}
            handleDeckFlashingChange={handleDeckFlashingChange}
            handleSteelPiers={handleSteelPiers}
            handleDeckDimensionChange={handleDeckDimensionChange}
            selectedRoomStyle={selectedRoomStyle}
          />
        </div>
        <div className="submit-choices">
          <SunroomSubmitButton
            selectedPermitOption={selectedPermitOption}
            selectedFoundationOption={selectedFoundationOption}
            selectedKneeWallOption={selectedKneeWallOption}
            selectedMountOption={selectedMountOption}
            personalInfo={{
              firstName,
              lastName,
              sender,
              email,
              address,
              city,
              stateProvince,
              zip,
              purchaseOrder,
            }}
            selectedExistingFoundationOption={selectedExistingFoundationOption}
            selectedNewFoundationOption={selectedNewFoundationOption}
            gradeHeightFeet={gradeHeightFeet}
            gradeHeightInches={gradeHeightInches}
            stepDownInches={stepDownInches}
            stepDownFeet={stepDownFeet}
            selectedWindLoadChange={selectedWindLoadChange.toString()}
            selectedRoofLoadChange={selectedRoofLoadChange.toString()}
            widthFeet={widthFeet}
            widthInches={widthInches}
            lengthFeet={lengthFeet}
            lengthInches={lengthInches}
            selectedKneeWallFeet={selectedKneeWallFeet}
            selectedKneeWallInches={selectedKneeWallInches}
            selectedFasciaInches={selectedFasciaInches}
            onRedBorderCheck={handleRedBorderCheck}
            selectedShingle={selectedShingle}
            selectedFanBeam={selectedFanBeam}
            selectedRoofDensity={selectedRoofDensity}
            heightAtHouse={heightAtHouse}
            frontHeight={frontHeight}
            selectedSkyviewChoice={selectedSkyviewChoice}
            selectedValueSeries={selectedValueSeries}
            selectedLEDLightChoice={selectedLEDLightChoice}
            deckFlashingOption={deckFlashingOption}
            selectedElectricalOption={selectedElectricalOption}
            selectedVertChannelOption={selectedVertChannelOption}
            selectedWindowWallStyle={selectedWindowWallStyle}
            selectedRoomStyle={selectedRoomStyle}
            selectedBotTransom={selectedBotTransom}
            selectedBackWallPanels={selectedBackWallPanels}
            frameOption={frameOption}
            fasciaTrimOption={fasciaTrimOption}
            exteriorKpOption={exteriorKpOption}
            interiorKpOption={interiorKpOption}
            selectedBotTransomOption={selectedBotTransomOption}
            selectedTopTransomOption={selectedTopTransomOption}
            selectedCleatsOrBond={selectedCleatsOrBond}
            selectedRoofPanelColorOption={selectedRoofPanelColorOption}
            selectedACLeftOption={ACLeftOption}
            selectedACCenterOption={ACCenterOption}
            selectedACRightOption={ACRightOption}
            selectedDoorOrientation={selectedDoorOrientation}
            selectedCodes={selectedCodes}
            selectedSwingDoorOrientation={selectedSwingDoorOrientation}
            selectedPatioDoorOrientation={selectedPatioDoorOrientation}
            selectedDoorHardware={selectedDoorHardware}
            doorOption={doorOption}
            doorWidth={doorWidth}
            wallLengthsFeet={wallLengthsFeet}
            wallLengthsInches={wallLengthsInches}
            SSHinges={SSHinges}
            selectedSunRoomType={selectedSunRoomType}
            selectedRailSideOption={selectedRailSideOption}
            selectedRailAmountOption={selectedRailAmountOption}
            selectedPavilionLayoutOption={selectedPavilionLayoutOption}
            initialSteelPiers={initialSteelPiers}
            deckWidthInchesOption={deckWidthInchesOption}
            deckLengthInchesOption={deckLengthInchesOption}
            deckWidthFeetOption={deckWidthFeetOption}
            deckLengthFeetOption={deckLengthFeetOption}
            isValidEmail={isValidEmail}
            selectedLocation={selectedLocation}
            locations={locations}
            selectedBreezeWay={selectedBreezeWay}
            selectedTopCustomReady={selectedTopCustomReady}
            wallERLLengthsFeet={wallERLLengthsFeet}
            wallERLLengthsInches={wallERLLengthsInches}
            wallERRLengthsFeet={wallERRLengthsFeet}
            wallERRLengthsInches={wallERRLengthsInches}
            wallERFLengthsFeet={wallERFLengthsFeet}
            wallERFLengthsInches={wallERFLengthsInches}
            doorERFOption={doorERFOption}
            doorERROption={doorERROption}
            doorERLOption={doorERLOption}
            doorERFWidth={doorERFWidth}
            doorERRWidth={doorERRWidth}
            doorERLWidth={doorERLWidth}
            selectedERLPatioDoorOrientation={selectedERLPatioDoorOrientation}
            selectedERLSwingDoorOrientation={selectedERLSwingDoorOrientation}
            selectedERRPatioDoorOrientation={selectedERRPatioDoorOrientation}
            selectedERRSwingDoorOrientation={selectedERRSwingDoorOrientation}
            selectedERFPatioDoorOrientation={selectedERFPatioDoorOrientation}
            selectedERFSwingDoorOrientation={selectedERFSwingDoorOrientation}
            selectedERLCodes={selectedERLCodes}
            selectedERRCodes={selectedERRCodes}
            selectedERFCodes={selectedERFCodes}
            selectedAngled={selectedAngled}
            selectedAngleOption={Number(selectedAngleOption)}
            wallStraightLengthsInches={wallStraightLengthsInches}
            wallStraightLengthsFeet={wallStraightLengthsFeet}
            doorStraightOption={doorStraightOption}
            doorStraightWidth={doorStraightWidth}
            selectedStraightSwingDoorOrientation={
              selectedStraightSwingDoorOrientation
            }
            selectedStraightPatioDoorOrientation={
              selectedStraightPatioDoorOrientation
            }
            selectedStraightCodes={selectedStraightCodes}
            leftAngleFeet={leftAngleFeet}
            leftAngleInches={leftAngleInches}
            rightAngleFeet={rightAngleFeet}
            rightAngleInches={rightAngleInches}
            screenRoom={screenRoom}
            isScreenRoom={isScreenRoom}
            selectedLocations={selectedLocations}
            selectedERFLocations={selectedERFLocations}
            selectedERRLocations={selectedERRLocations}
            selectedERLLocations={selectedERLLocations}
            chairRailWidthFeet={chairRailWidthFeet}
            chairRailWidthInches={chairRailWidthInches}
            bottomKPWidthFeet={bottomKPWidthFeet}
            bottomKPWidthInches={bottomKPWidthInches}
            selectedTEMOCornersOption={selectedTEMOCornersOption}
            screenDoorWidth={screenDoorWidth}
            vinylOption={vinylOption}
            glassRows={glassRows}
            bays={bays}
            setCurrentCADImagesFiles={setCurrentCADImagesFiles}
            currentCADImagesFiles={currentCADImagesFiles}
            drawingImages={drawingImages}
            setDrawingImages={setDrawingImages}
          />
        </div>
        {selectedRoomStyle !== "Existing Roof" &&
          selectedSunRoomType !== "Solarium" && (
            <div className="value-choices">
              <ValueSeries
                handleValueSeriesOption={handleValueSeriesOption}
                redBorderFields={fieldsWithRedBorder}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                fasciaTrimOption={fasciaTrimOption}
                setFasciaTrimOption={setFasciaTrimOption}
              />
            </div>
          )}
        <div className="backWallPanels">
          <BackWallPanels
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            handleBackWallPanelsOption={handleBackWallPanelsOption}
          />
          {selectedSunRoomType === "Value View" && (
            <ScreenRoom
              handleScreenRoomChange={handleScreenRoomChange}
              redBorderFields={fieldsWithRedBorder}
              setFieldsWithRedBorder={setFieldsWithRedBorder}
            />
          )}
          {selectedRoomStyle === "Existing Roof" && (
            <TEMOCorners
              handleSelectedTEMOCornersChoice={handleSelectedTEMOCornersChoice}
              redBorderFields={fieldsWithRedBorder}
              setFieldsWithRedBorder={setFieldsWithRedBorder}
            />
          )}
          {selectedRoomStyle !== "Existing Roof" && (
            <FanBeam
              handleFanBeamOption={handleFanBeamOption}
              redBorderFields={fieldsWithRedBorder}
              setFieldsWithRedBorder={setFieldsWithRedBorder}
            />
          )}
        </div>
        {selectedRoomStyle !== "Existing Roof" && (
          <RoofPanelColors
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            handleRoofPanelColorOption={handleRoofPanelColorOption}
            selectedSkyviewChoice={selectedSkyviewChoice}
            selectedRoofDensity={selectedRoofDensity}
            selectedShingle={selectedShingle}
          />
        )}

        <SunRoomWalls
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          setLengthFeet={setLengthFeet}
          setLengthInches={setLengthInches}
          setWidthFeet={setWidthFeet}
          setWidthInches={setWidthInches}
          selectedRoomStyle={selectedRoomStyle}
          setSelectedDoorOrientation={setSelectedDoorOrientation}
          selectedCodes={selectedCodes}
          setSelectedCodes={setSelectedCodes}
          selectedDoorHardware={selectedDoorHardware}
          setSelectedDoorHardware={setSelectedDoorHardware}
          selectedPatioDoorOrientation={selectedPatioDoorOrientation}
          setSelectedPatioDoorOrientation={setSelectedPatioDoorOrientation}
          selectedSwingDoorOrientation={selectedSwingDoorOrientation}
          doorOption={doorOption}
          doorWidth={doorWidth}
          setDoorOption={setDoorOption}
          setDoorWidth={setDoorWidth}
          setSelectedSwingDoorOrientation={setSelectedSwingDoorOrientation}
          redBorderFields={fieldsWithRedBorder}
          setFieldsWithRedBorder={setFieldsWithRedBorder}
          selectedSunRoomType={selectedSunRoomType}
          handleWallLengthFeetChange={handleWallLengthFeetChange}
          handleWallLengthInchesChange={handleWallLengthInchesChange}
          wallLengthsFeet={wallLengthsFeet}
          wallLengthsInches={wallLengthsInches}
          SSHinges={SSHinges}
          handleSSHingesOptionChange={handleSSHingesOptionChange}
          selectedPavilionLayoutOption={selectedPavilionLayoutOption}
          selectedRailSideOption={selectedRailSideOption}
          selectedBreezeWay={selectedBreezeWay}
          setWallERLLengthsFeet={setWallERLLengthsFeet}
          setWallERLLengthsInches={setWallERLLengthsInches}
          setWallERRLengthsFeet={setWallERRLengthsFeet}
          setWallERRLengthsInches={setWallERRLengthsInches}
          setWallERFLengthsFeet={setWallERFLengthsFeet}
          setWallERFLengthsInches={setWallERFLengthsInches}
          wallERLLengthsFeet={wallERLLengthsFeet}
          wallERLLengthsInches={wallERLLengthsInches}
          wallERRLengthsFeet={wallERRLengthsFeet}
          wallERRLengthsInches={wallERRLengthsInches}
          wallERFLengthsFeet={wallERFLengthsFeet}
          wallERFLengthsInches={wallERFLengthsInches}
          setWallLengthsFeet={setWallLengthsFeet}
          setWallLengthsInches={setWallLengthsInches}
          setLeftCavities={setLeftCavities}
          setRightCavities={setRightCavities}
          setFrontCavities={setFrontCavities}
          setDoorERFOption={setDoorERFOption}
          setDoorERROption={setDoorERROption}
          setDoorERLOption={setDoorERLOption}
          doorERFOption={doorERFOption}
          doorERROption={doorERROption}
          doorERLOption={doorERLOption}
          doorERFWidth={doorERFWidth}
          setDoorERFWidth={setDoorERFWidth}
          doorERRWidth={doorERRWidth}
          setDoorERRWidth={setDoorERRWidth}
          doorERLWidth={doorERLWidth}
          setDoorERLWidth={setDoorERLWidth}
          lastWallLengthUpdate={lastWallLengthUpdate}
          setLastWallLengthUpdate={setLastWallLengthUpdate}
          selectedERLPatioDoorOrientation={selectedERLPatioDoorOrientation}
          selectedERLSwingDoorOrientation={selectedERLSwingDoorOrientation}
          selectedERRPatioDoorOrientation={selectedERRPatioDoorOrientation}
          selectedERRSwingDoorOrientation={selectedERRSwingDoorOrientation}
          selectedERFPatioDoorOrientation={selectedERFPatioDoorOrientation}
          selectedERFSwingDoorOrientation={selectedERFSwingDoorOrientation}
          setSelectedERLSwingDoorOrientation={
            setSelectedERLSwingDoorOrientation
          }
          setSelectedERLPatioDoorOrientation={
            setSelectedERLPatioDoorOrientation
          }
          setSelectedERRSwingDoorOrientation={
            setSelectedERRSwingDoorOrientation
          }
          setSelectedERRPatioDoorOrientation={
            setSelectedERRPatioDoorOrientation
          }
          setSelectedERFSwingDoorOrientation={
            setSelectedERFSwingDoorOrientation
          }
          setSelectedERFPatioDoorOrientation={
            setSelectedERFPatioDoorOrientation
          }
          setSelectedERLCodes={setSelectedERLCodes}
          setSelectedERRCodes={setSelectedERRCodes}
          setSelectedERFCodes={setSelectedERFCodes}
          selectedERLCodes={selectedERLCodes}
          selectedERRCodes={selectedERRCodes}
          selectedERFCodes={selectedERFCodes}
          selectedERLButtonIndex={selectedERLButtonIndex}
          selectedERRButtonIndex={selectedERRButtonIndex}
          selectedERFButtonIndex={selectedERFButtonIndex}
          setSelectedERLButtonIndex={setSelectedERLButtonIndex}
          setSelectedERRButtonIndex={setSelectedERRButtonIndex}
          setSelectedERFButtonIndex={setSelectedERFButtonIndex}
          selectedERLImages={selectedERLImages}
          selectedERRImages={selectedERRImages}
          selectedERFImages={selectedERFImages}
          setSelectedERLImages={setSelectedERLImages}
          setSelectedERRImages={setSelectedERRImages}
          setSelectedERFImages={setSelectedERFImages}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          selectedButtonIndex={selectedButtonIndex}
          setSelectedButtonIndex={setSelectedButtonIndex}
          handleCodeSelect={handleCodeSelect}
          setLeftSideDivs={setLeftSideDivs}
          setRightSideDivs={setRightSideDivs}
          setFrontSideDivs={setFrontSideDivs}
          setLeftSidePickMeDivs={setLeftSidePickMeDivs}
          setRightSidePickMeDivs={setRightSidePickMeDivs}
          setFrontSidePickMeDivs={setFrontSidePickMeDivs}
          leftSideDivs={leftSideDivs}
          rightSideDivs={rightSideDivs}
          frontSideDivs={frontSideDivs}
          leftSidePickMeDivs={leftSidePickMeDivs}
          rightSidePickMeDivs={rightSidePickMeDivs}
          frontSidePickMeDivs={frontSidePickMeDivs}
          verticalRightLines={verticalRightLines}
          setVerticalRightLines={setVerticalRightLines}
          blackRightSquares={blackRightSquares}
          setBlackRightSquares={setBlackRightSquares}
          setRightPickMes={setRightPickMes}
          pickMeRightDivs={pickMeRightDivs}
          blackFrontSquares={blackFrontSquares}
          setBlackFrontSquares={setBlackFrontSquares}
          setFrontPickMes={setFrontPickMes}
          pickMeFrontDivs={pickMeFrontDivs}
          horizontalLines={horizontalLines}
          setHorizontalLines={setHorizontalLines}
          blackLeftSquares={blackLeftSquares}
          setBlackLeftSquares={setBlackLeftSquares}
          setLeftPickMes={setLeftPickMes}
          pickMeLeftDivs={pickMeLeftDivs}
          setVerticalLeftLines={setVerticalLeftLines}
          verticalLeftLines={verticalLeftLines}
          setIndexFront={setIndexFront}
          setIndexRight={setIndexRight}
          setIndexLeft={setIndexLeft}
          indexFront={indexFront}
          indexRight={indexRight}
          indexLeft={indexLeft}
          setOpenFrontSingleDrawing={setOpenFrontSingleDrawing}
          setOpenRightSingleDrawing={setOpenRightSingleDrawing}
          setOpenLeftSingleDrawing={setOpenLeftSingleDrawing}
          openFrontSingleDrawing={openFrontSingleDrawing}
          openRightSingleDrawing={openRightSingleDrawing}
          openLeftSingleDrawing={openLeftSingleDrawing}
          handleDoorOptionChange={handleDoorOptionChange}
          handleDoorWidthChange={handleDoorWidthChange}
          isScreenRoom={isScreenRoom}
          setSelectedLocations={setSelectedLocations}
          setSelectedERFLocations={setSelectedERFLocations}
          setSelectedERRLocations={setSelectedERRLocations}
          setSelectedERLLocations={setSelectedERLLocations}
          selectedLocations={selectedLocations}
          selectedERFLocations={selectedERFLocations}
          selectedERRLocations={selectedERRLocations}
          selectedERLLocations={selectedERLLocations}
          handleDoorLocationChange={handleDoorLocationChange}
          handleSelectedScreenDoorWidthChoice={
            handleSelectedScreenDoorWidthChoice
          }
          handleBays={handleBays}
          handleSelectedPatioDoorOrientation={
            handleSelectedPatioDoorOrientation
          }
          currentImage={currentImage}
          handleSwingDoorOrientation={handleSwingDoorOrientation}
        />
        {selectedSunRoomType &&
          selectedAngled === "Yes" &&
          selectedAngleOption.toString() && (
            <AngledWalls
              redBorderFields={fieldsWithRedBorder}
              selectedAngleOption={selectedAngleOption}
              setFieldsWithRedBorder={setFieldsWithRedBorder}
              rightSideMissingWallLength={rightSideMissingWallLength}
              setRightMissingWallLength={setRightMissingWallLength}
              setRightSideMissingWallDiv={setRightSideMissingWallDiv}
              leftSideMissingWallLength={leftSideMissingWallLength}
              setLeftMissingWallLength={setLeftMissingWallLength}
              setLeftSideMissingWallDiv={setLeftSideMissingWallDiv}
              leftSideMissingWallDiv={leftSideMissingWallDiv}
              rightSideMissingWallDiv={rightSideMissingWallDiv}
              handleCodeSelect={handleCodeSelect}
              selectedStraightCodes={selectedStraightCodes}
              setSelectedStraightCodes={setSelectedStraightCodes}
              selectedStraightButtonIndex={selectedStraightButtonIndex}
              setSelectedStraightButtonIndex={setSelectedStraightButtonIndex}
              selectedStraightImages={selectedStraightImages}
              setSelectedStraightImages={setSelectedStraightImages}
              selectedStraightPatioDoorOrientation={
                selectedStraightPatioDoorOrientation
              }
              selectedStraightSwingDoorOrientation={
                selectedStraightSwingDoorOrientation
              }
              setSelectedStraightSwingDoorOrientation={
                setSelectedStraightSwingDoorOrientation
              }
              setSelectedStraightPatioDoorOrientation={
                setSelectedStraightPatioDoorOrientation
              }
              selectedSunRoomType={selectedSunRoomType}
              lastWallLengthUpdate={lastWallLengthUpdate}
              setLastWallLengthUpdate={setLastWallLengthUpdate}
              handleDoorWidthChange={handleDoorWidthChange}
              setDoorStraightWidth={setDoorStraightWidth}
              doorStraightWidth={doorStraightWidth}
              handleDoorOptionChange={handleDoorOptionChange}
              doorStraightOption={doorStraightOption}
              setDoorStraightOption={setDoorStraightOption}
              handleWallLengthInchesChange={handleWallLengthInchesChange}
              setWallStraightLengthsFeet={setWallStraightLengthsFeet}
              setWallStraightLengthsInches={setWallStraightLengthsInches}
              handleWallLengthFeetChange={handleWallLengthFeetChange}
              wallStraightLengthsFeet={wallStraightLengthsFeet}
              wallStraightLengthsInches={wallStraightLengthsInches}
              selectedDoorHardware={selectedDoorHardware}
              setSelectedDoorHardware={setSelectedDoorHardware}
              SSHinges={SSHinges}
              handleSSHingesOptionChange={handleSSHingesOptionChange}
              setRightAngleFeet={setRightAngleFeet}
              setRightAngleInches={setRightAngleInches}
              setLeftAngleFeet={setLeftAngleFeet}
              setLeftAngleInches={setLeftAngleInches}
              rightAngleFeet={rightAngleFeet}
              rightAngleInches={rightAngleInches}
              leftAngleFeet={leftAngleFeet}
              leftAngleInches={leftAngleInches}
              isScreenRoom={isScreenRoom}
              handleSelectedPatioDoorOrientation={
                handleSelectedPatioDoorOrientation
              }
              currentImage={currentImage}
              handleSwingDoorOrientation={handleSwingDoorOrientation}
            />
          )}
        {selectedAngled !== "Yes" && (
          <StructureLayoutImages
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            handlePavilionLayoutOptionChange={handlePavilionLayoutOptionChange}
            handleRailSideOption={handleRailSideOption}
            handleRailAmountOption={handleRailAmountOption}
            isSunroom={isSunroom}
            selectedBreezeWay={selectedBreezeWay}
            handleBreezeWayChange={handleBreezeWayChange}
            selectedRoomStyle={selectedRoomStyle}
          />
        )}
        {selectedAngled === "Yes" && (
          <AngledLayout
            redBorderFields={fieldsWithRedBorder}
            setSelectedAngleOption={setSelectedAngleOption}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
          />
        )}
        {selectedRoomStyle !== "Existing Roof" && (
          <RoofSpanKey isScreenRoom={isScreenRoom} />
        )}
      </div>
    </div>
  );
};

Sunrooms.propTypes = {
  isScreenRoom: PropTypes.bool,
};

export default Sunrooms;
