import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";

const SubmitButtonForm = ({
  sentCorrectly,
  submitIsLoading,
  selectedPermitOption,
  openModal,
}) => {
  const newOpenModal = (e) => {
    e.preventDefault();
    openModal(e);
  };

  const headerRef = useRef(null);
  const buttonRef = useRef(null);
  const [isTextScrollable, setIsTextScrollable] = useState(false);
  const [isButtonScrollable, setIsButtonScrollable] = useState(false);

  const checkTextScrollability = useCallback(() => {
    const header = headerRef.current;
    if (header) {
      setIsTextScrollable(header.scrollWidth > header.clientWidth);
    }
  }, []);

  const checkButtonScrollability = useCallback(() => {
    const button = buttonRef.current;
    if (button) {
      setIsButtonScrollable(button.scrollWidth > button.clientWidth);
    }
  }, []);

  useEffect(() => {
    const button = buttonRef.current;
    if (!button) return;

    const resizeObserver = new ResizeObserver(() => {
      checkButtonScrollability();
    });

    resizeObserver.observe(button);

    return () => {
      resizeObserver.disconnect();
    };
  }, [checkButtonScrollability]);

  useEffect(() => {
    const header = headerRef.current;
    if (!header) return;

    const resizeObserver = new ResizeObserver(() => {
      checkTextScrollability();
    });

    resizeObserver.observe(header);

    return () => {
      resizeObserver.disconnect();
    };
  }, [checkTextScrollability]);

  useEffect(() => {
    checkTextScrollability();
  }, [checkTextScrollability]);

  useEffect(() => {
    checkButtonScrollability();
  }, [checkButtonScrollability]);

  const setHeaderRef = useCallback(
    (node) => {
      headerRef.current = node;
      checkTextScrollability();
    },
    [checkTextScrollability],
  );

  const setButtonRef = useCallback(
    (node) => {
      buttonRef.current = node;
      checkButtonScrollability();
    },
    [checkButtonScrollability],
  );

  return (
    <form id="submitbutton" onSubmit={(e) => newOpenModal(e)}>
      {sentCorrectly !== true && submitIsLoading !== true && (
        <div style={{ overflow: "hidden" }}>
          <div
            ref={setHeaderRef}
            style={{
              whiteSpace: "nowrap",
              width: "100%",
            }}
            className={`realredasterisk ${isTextScrollable ? "scrollable" : ""}`}
          >
            <div
              className="fontsizeforinputsrow"
              style={{ fontSize: "2vh", display: "inline-block" }}
            >
              Any&nbsp;Area&nbsp;With&nbsp;This&nbsp;&#10088;
              <b>
                <span className="redasterisk">&#10033;</span>
              </b>
              &#10089;&nbsp;Is
              <br />
              <u>
                <b>REQUIRED</b>
              </u>
              &nbsp;Before&nbsp;Submitting
            </div>
          </div>
          <button
            id="submit"
            type="submit"
            className="showpdfbuttons submitbutton"
            style={{ width: "9vw", overflow: "hidden" }}
            ref={setButtonRef}
          >
            <div className={`${isButtonScrollable ? "scrollable" : ""}`}>
              {parse(
                // selectedPermitOption === "NO"
                /*?*/ "Send to TEMO",
                // : "View&nbsp;Preliminary<br/>Drawing&nbsp;Package",
              )}
            </div>
          </button>
        </div>
      )}
    </form>
  );
};

SubmitButtonForm.propTypes = {
  sentCorrectly: PropTypes.bool,
  submitIsLoading: PropTypes.bool,
  selectedPermitOption: PropTypes.string,
  openModal: PropTypes.func,
};

export default SubmitButtonForm;
